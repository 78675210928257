<template>
  <Modal
    id="cancelDeliveryModal"
    :isOpen.sync="isModalOpen"
    :header="$t('dispatch.cancelScheduledDelivery')"
    :buttonLabel="$t('dispatch.cancelDelivery')"
    :buttonLabelClose="$t('dispatch.keepDelivery')"
    size="is-medium"
    :loading="loading"
    @cancel="() => {}"
    @save="onSubmit"
  >
    <form class="deliveryForm" ref="form" @submit.prevent="onSubmit">
      <label>{{ $t('dispatch.cancelModalLabel') }}</label>
      <Field
        :label="$t('dispatch.cancelPlaceholder')"
        v-model="params.comments"
        type="textarea"
        :upper="false"
        required
        :rows="2"
      />
      <Autocomplete
        class="required mb-5 mt-3"
        :label="$t('manifest.selectReason')"
        :placeholder="$t('global.select')"
        v-model="params.non_delivery_reason_id"
        :api="{
          url: '/catalogs/non_delivery_reason',
          full: true
        }"
        model="id"
        field="description"
        :disabled="this.loading.save ? true : false"
        required
      />
      <Field
        v-if="params.non_delivery_reason_id === 4"
        :label="$t('manifest.otherReason')"
        type="textarea"
        :upper="false"
        :rows="2"
        v-model="params.other_reason"
        required
      />
    </form>
  </Modal>
</template>

<script>
import { Field, Modal, Autocomplete } from '@/components';

export default {
  components: {
    Autocomplete,
    Modal,
    Field
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { dispatch: { manifest } }) => {
      if (type === 'dispatch/manifest') {
        this.manifestId = manifest.id;
        this.params = { comments: '', other_reason: '', non_delivery_reason_id: '' };
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    return {
      isModalOpen: this.isOpen,
      loading: { save: false },
      params: { comments: '', other_reason: '', non_delivery_reason_id: '' },
      unsubscribe: null
    };
  },
  methods: {
    async onSubmit() {
      this.loading.save = true;
      if (this.validate()) {
        const deliveredDate = this.$moment().utc().format('Y-MM-DD HH:mm:ss');
        try {
          await this.Api.post(
            `delivery_manifest/${this.manifestId}/delivery_events/${this.eventId}/change_status`,
            { ...this.params, delivery_event_status_id: 7, delivered_date: deliveredDate }
          );
          this.$store.commit('dispatch/updateManifestEvents');
          this.$store.commit('dispatch/reloadTable');
          this.isModalOpen = false;
        } catch (error) {
          console.log(error);
        }
      }
      this.loading.save = false;
    },
    validate() {
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      return htmlValidator;
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      if (!value) this.params = { comments: '', other_reason: '', non_delivery_reason_id: '' };
      this.$emit('update:isOpen', value);
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    eventId: {
      type: Number,
      require: true
    }
  }
};
</script>

<style lang="sass" scoped>
#cancelDeliveryModal
  ::v-deep
    .modal-title
      i
        padding-left: 3px
    .modal-content-container .slot
      overflow: visible
    .modal-footer button
      &:last-child
        margin-right: 0px
.Field
  margin-top: 24px
  margin-bottom: 48px
</style>
