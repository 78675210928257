export const vapidKey =
  'BGQx_YCnPHL4lnHdtCvs5WyxeCLKfXeOBYsvdUolAnwsoOnxVC164ouOdhieeAa4EZw_VnDsyXXvIa_tNhHtizg';
export const config = {
  apiKey: 'AIzaSyAgt4IXauPBthkbFls-AI6XTJ_Va-8Rguk',
  authDomain: 'testing-nemt-rx-deliveries.firebaseapp.com',
  projectId: 'testing-nemt-rx-deliveries',
  storageBucket: 'testing-nemt-rx-deliveries.appspot.com',
  messagingSenderId: '465290905479',
  appId: '1:465290905479:web:6478cf8980b68a96ca7522',
  measurementId: 'G-0408Y9VLSY'
};
