import Api from '@/utils/Api.js';
import router from '@/router';
import { enc, AES } from 'crypto-js';
import { setCookie, getCookie, removeCookie } from '@/utils/Cookies.js';
import { delayer } from '../../utils/Helpers';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { getOffetFromTimeZone } from '../../utils/Datetime';

const COOKIE_ID = process.env.VUE_APP_COOKIE_ID || null;

const _encrypt = (data) => {
  if (typeof data === 'object') data = JSON.stringify(data);
  return AES.encrypt(data, 'xD').toString();
};
const _decrypt = (encrypted) => {
  if (encrypted) {
    const bytes = AES.decrypt(encrypted, 'xD');
    const data = bytes.toString(enc.Utf8);
    return JSON.parse(data);
  } else return null;
};

export default {
  namespaced: false,
  state: {
    AUTH: _decrypt(getCookie('AH')),
    permissions: _decrypt(localStorage.getItem(`${COOKIE_ID}_pIds`)) || [],
    isDeauthing: false
  },
  getters: {
    AUTH: (state) => state.AUTH,
    Authorization: (state) => state.AUTH && state.AUTH.Authorization,
    hasAuth: (state) => !!state.AUTH,
    permissions: (state) => state.permissions,
    timeZone: (state) => state.AUTH && state.AUTH.time_zone,
    offset: (state, getters) => getOffetFromTimeZone(getters.timeZone)
  },
  mutations: {
    AUTH(state, P) {
      const data = {
        Authorization: P.Authorization,
        default_language: P.default_language,
        default_state: P.default_state,
        email: P.email,
        employee_id: P.employee_id,
        full_name: P.full_name,
        id: P.id,
        is_first_signin: P.is_first_signin,
        time_zone: P.time_zone,
        user_type_id: P.user_type_id
      };
      const { exp } = jwt_decode(P.Authorization);
      setCookie('AH', _encrypt(data), moment.unix(exp).toDate());
      state.AUTH = data;
      Api.Authorization = data.Authorization;
    },
    async deAUTH(state, { authorization, redirect = true, allowSignOut = true } = {}) {
      if (state.isDeauthing) return;
      state.isDeauthing = true;
      try {
        if (allowSignOut) {
          if (authorization) Api.Authorization = authorization;
          await Api.delete(`users/sign_out`);
        }
      } catch (error) {
        if (error.status !== 403) return;
      }
      state.AUTH = null;
      sessionStorage.clear();
      localStorage.clear();
      removeCookie('AH');
      Api.Authorization = null;
      state.isDeauthing = false;
      if (redirect) window.location.reload();
    },
    setPermissions(state, payload) {
      state.permissions = payload;
      localStorage.setItem(`${COOKIE_ID}_pIds`, _encrypt(payload));
    },
    uninstallServiceWorker() {
      if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (let registration of registrations) {
            registration.unregister();
          }
        });
      }
    }
  },
  actions: {
    AUTH: async ({ commit, dispatch, state }, AUTH_data) => {
      try {
        const { Authorization: auth, id, is_first_signin } = AUTH_data;
        if (is_first_signin) {
          router.push({ path: `/register-password`, query: { auth, id } });
        } else {
          commit('AUTH', AUTH_data);
          await dispatch('getPermisions', AUTH_data);
          commit('map/hasAuth', true);
          state.permissions.find((permission) => permission.path === '/participants')
            ? router.push({ name: 'participants' })
            : router.push({ name: 'dispatch' });
        }
      } catch (Err) {
        dispatch('deAUTH');
      }
    },
    checkAUTH: ({ commit, getters }) => {
      const cookie = getCookie('AH');
      const { hasAuth } = getters;
      if (!cookie && hasAuth) commit('deAUTH');
    },
    deAUTH: async ({ commit }, params = {}) => {
      commit('map/hasAuth', false);
      commit('uninstallServiceWorker');
      commit('deAUTH', params);
      await delayer(5000);
    },
    getPermisions: async ({ commit }) => {
      const { data } = await Api.get('/users/routes');
      const permissions = data.map((P) => ({ method: P.http_method, path: P.path_route }));
      commit('setPermissions', permissions);
    }
  }
};
