<template>
  <div id="deliver-restriction" class="flex f-jc">
    <div :class="inline ? 'flex f-wrap f-jsb inline' : ''">
      <div v-for="(restriction, key) in restrictionOptions" :key="key" class="flex f-ai">
        <b-field class="restriction-options" grouped>
          <b-radio type="primary" v-model="currentOption" :native-value="restriction.option">
            {{ restriction.label }}
          </b-radio>
        </b-field>
        <TimePicker
          class="mr-3"
          :convertUtc="UTC"
          :disabled="restriction.option !== currentOption"
          :value="getTimePickerValue(restriction)"
          @input="(value) => onInput(key, value)"
          @display="(value) => onDisplay(key, value)"
          :offset="offset"
          :dropDirection="dropDirection"
          :containerId="containerId"
        />
        <template v-if="key === 'between'">
          <p class="mb-2 mr-3">{{ $t('global.and') }}</p>
          <TimePicker
            :convertUtc="UTC"
            :disabled="restriction.option !== currentOption"
            :value="getTimePickerValue(restriction, true)"
            @input="(value) => onInput(key, value, 'before')"
            @display="(value) => onDisplay(key, value, 'before')"
            :minTime="minuteBeforeSecondaryTime"
            :offset="offset"
            :dropDirection="dropDirection"
            :containerid="containerId"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { TimePicker } from '.';

export default {
  components: {
    TimePicker
  },
  mounted() {
    this.setBaseDates();
    this.setDates();
  },
  data() {
    return {
      currentOption: 1,
      baseDates: null,
      restrictionOptions: {
        after: {
          option: 1,
          label: this.$t('deliveries.deliverAfter'),
          value: '',
          default: '',
          display: ''
        },
        before: {
          option: 2,
          label: this.$t('deliveries.deliverBefore'),
          value: '',
          default: '',
          display: ''
        },
        between: {
          option: 3,
          label: this.$t('deliveries.deliverBetween'),
          after: '',
          afterDefault: '',
          before: '',
          beforeDefault: '',
          display: ''
        }
      }
    };
  },
  computed: {
    iOffet() {
      return this.offset || -this.$store.getters.offset;
    },
    currentRestriction() {
      const result = { start_time: null, end_time: null, display: null };
      if (this.currentOption === 1) {
        const { after } = this.restrictionOptions;
        return { ...result, start_time: after.value, display: after.display };
      }
      if (this.currentOption === 2) {
        const { before } = this.restrictionOptions;
        return { ...result, end_time: before.value, display: before.display };
      }
      if (this.currentOption === 3) {
        const { between } = this.restrictionOptions;
        return {
          ...result,
          start_time: between.after,
          end_time: between.before,
          display: between.display
        };
      }
      return result;
    },
    minuteBeforeSecondaryTime() {
      if (this.currentOption === 3) {
        const value = this.restrictionOptions?.after?.value || this.defaultValues.after;
        if (!value) return;
        const result = (this.UTC ? this.toLocal(value) : this.$moment(value, 'HH:mm:ss'))
          .add(30, 'minutes')
          .format('HH:mm:ss');
        return result;
      }
      return null;
    }
  },
  methods: {
    getTimePickerValue(option, isSecondary) {
      const { value, default: deff, after, before, afterDefault, beforeDefault } = option;
      if (isSecondary) {
        return before || beforeDefault;
      } else {
        return value || deff || after || afterDefault;
      }
    },
    onDisplay(key, value) {
      const after = this.restrictionOptions.after.display;
      if (key === 'between') value = `${after} - ${value}`;
      this.restrictionOptions[key].display = value;
    },
    onInput(key, value, secondaryKey) {
      if (value === null) return;
      if (key === 'between') {
        if (secondaryKey) {
          this.restrictionOptions[key].before = value;
          this.restrictionOptions[secondaryKey].value = value;
        } else {
          this.restrictionOptions.after.value = value;
          this.restrictionOptions[key].after = value;
        }
      } else {
        this.restrictionOptions[key].value = value;
        this.restrictionOptions.between[key] = value;
      }
    },
    setBaseDates() {
      const { start_time } = this.defaultValues;
      if (!this.currentRestriction.start_time && !this.currentRestriction.end_time)
        this.updateOptionValues(start_time);
    },
    setDates(value) {
      let { start_time, end_time } = value || this.value;
      const { start_time: DST, end_time: DET } = this.defaultValues;
      this.updateRestrictionOptions({ start_time, end_time, DST, DET });
    },
    toLocal(UTC, format) {
      const time = this.$moment(UTC, 'HH:mm:ss').add(this.iOffet, 'hours');
      if (format) return time.format('HH:mm:ss');
      return time;
    },
    toUTC(local, format) {
      const time = this.$moment(local, 'HH:mm:ss').utc();
      if (format) return time.format('HH:mm:ss');
      return time;
    },
    updateCurrentOption() {
      const start_time = this.restrictionOptions.after.value;
      const end_time = this.restrictionOptions.before.value;
      if (start_time && end_time) this.currentOption = 3;
      else if (end_time) this.currentOption = 2;
      else if (start_time) this.currentOption = 1;
    },
    updateOptionDefaultValues(DST, DET) {
      const parseTime = (value) => (value && this.UTC ? this.toUTC(value, true) : value);
      this.restrictionOptions.after.default = parseTime(DST);
      this.restrictionOptions.before.default = parseTime(DET);
      this.restrictionOptions.between.afterDefault = parseTime(DST);
      this.restrictionOptions.between.beforeDefault = parseTime(DET);
    },
    updateOptionValues(start_time = 0, end_time = 0) {
      this.restrictionOptions.after.value = start_time;
      this.restrictionOptions.before.value = end_time;
      this.restrictionOptions.between.before = end_time;
      this.restrictionOptions.between.after = start_time;
    },
    updateRestrictionOptions({ start_time, end_time, DST, DET }) {
      this.updateOptionDefaultValues(DST, DET);
      this.updateOptionValues(start_time, end_time);
      this.updateCurrentOption();
    }
  },
  watch: {
    currentRestriction(value) {
      this.$emit('change', value);
    },
    defaultValues() {
      this.setBaseDates();
    },
    value(value) {
      this.setDates(value);
    }
  },
  props: {
    UTC: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    containerId: { type: String, default: 'deliver-restriction' },
    dropDirection: { type: String, default: 'auto' },
    offset: { type: Number, default: 0 },
    value: { type: Object, default: () => null },
    defaultValues: {
      type: Object,
      default: () => ({ start_time: '08:00:00', end_time: '10:00:00' })
    }
  }
};
</script>

<style lang="sass" scoped>
.restriction-options
  min-width: 150px
.inline
  .restriction-options
    min-width: auto
    white-space: nowrap
</style>
