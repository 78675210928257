import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Buefy from 'buefy';
import i18n from './locales';
import './assets/sass/style.sass';
import defaultLayout from './layouts/default.vue';
import Api from '@/utils/Api.js';
import { Secure } from '@/utils/Secure';
import FirebaseMessaging from '@/utils/FirebaseMessaging.js';
import moment from 'moment-timezone';
import PortalVue from 'portal-vue';
import VueApexCharts from 'vue-apexcharts';
import VTooltip from 'v-tooltip';
import { Notification, toast } from '@/utils/dialog';
const Authorization = store.getters.Authorization;

Api.Authorization = Authorization;
moment.tz.setDefault(store.getters.timeZone || undefined);

Vue.use(Buefy);
Vue.use(VTooltip);
Vue.use(PortalVue);
Vue.use(VueApexCharts);
Vue.config.productionTip = false;
Vue.component('default-layout', defaultLayout);
Vue.component('apexchart', VueApexCharts);
Vue.prototype.Api = Api;
Vue.prototype.Secure = Secure;
Vue.prototype.$pushNotifications = new FirebaseMessaging(false);
Vue.prototype.$moment = moment;
Vue.prototype.$Notification = Notification;
Vue.prototype.$toast = toast;
Vue.prototype.ENV_IS_PRODUCTION = process.env.NODE_ENV === 'production';
new Vue({
  i18n,
  router,
  store,
  created() {
    if (Authorization) this.$pushNotifications.initialize();
  },
  render: (h) => h(App)
}).$mount('#app');
