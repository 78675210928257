<template>
  <form ref="form" class="address-picker">
    <AddressLocationModal
      :value="oAddress"
      :isOpen.sync="isAddressMapModalOpen"
      :renewPosition="positionRenew"
      @pickPosition="onMapprPickedPosition"
    />
    <div class="ap-container flex f-wrap">
      <slot name="atBegin" />
      <Field
        :btnAction="btnActionConfig"
        :disabled="disabledFields"
        :label="$t('address.address', [])"
        :maxlength="addressMaxLength"
        :useActionBtn="useAddressParser"
        @actionClick="processAddress"
        @input="handleAddressInput"
        @model="(value) => setModel(value, 'address')"
        class="ap-address"
        :avoidSpecialChars="false"
        required
        v-model="oAddress.address"
        :loading="oLoading"
      >
        <template #end v-if="lastAddressQuery">
          <Tooltip class="original-address" :label="lastAddressQuery">
            <b-icon icon="help-circle" size="" />
          </Tooltip>
        </template>
      </Field>
      <Field
        :disabled="disabledFields"
        :label="$t('address.address', [1])"
        :maxlength="address1MaxLenght"
        @model="(value) => setModel(value, 'address_1')"
        :avoidSpecialChars="false"
        class="ap-address1"
        v-model="oAddress.address_1"
      />
      <Autocomplete
        :api="{
          url: 'catalogs/cities',
          query: 'name',
          params: [{ id: 'state_code', value: oAddress.state }]
        }"
        :disabled="disabledFields"
        :dropdownPosition="dropdownPosition"
        :label="$t('address.city')"
        autocomplete="no"
        class="ap-city"
        field="name"
        model="name"
        required
        keep-first
        v-model="oAddress.city"
        @isLoading="handleLoading"
      />
      <Autocomplete
        tabindex="-1"
        :api="{
          url: 'catalogs/states',
          full: true
        }"
        :disabled="disabledFields"
        :dropdownPosition="dropdownPosition"
        :label="$t('address.state')"
        :validationMessage="$t('address.state')"
        @select="onChangeState"
        autocomplete="no"
        class="ap-state"
        field="code"
        model="code"
        required
        v-model="oAddress.state"
        storeResults
        @isLoading="handleLoading"
      />
      <Autocomplete
        :api="{
          url: 'catalogs/zipcodes',
          query: 'zipcode',
          params: [{ id: 'state_code', value: stateCode }]
        }"
        :disabled="disabledFields"
        :dropdownPosition="dropdownPosition"
        :label="$t('address.zp')"
        :minToSearch="2"
        :validationMessage="$t('validations.zip')"
        autocomplete="no"
        class="ap-zipcode"
        field="zipcode"
        model="zipcode"
        :pattern="zipcodeRegex"
        keep-first
        required
        maxlength="5"
        v-model="oAddress.zipcode"
        @isLoading="handleLoading"
      />
      <Link
        v-if="doPickPosition"
        class="ap-position"
        :class="positionStatute.class"
        :tooltipClass="positionStatute.class"
        :label="positionStatute.title"
        @click="onPickPosition(oAddress)"
        :position="tooltipPosition"
        :disabled="!isPickPositionAvailable"
        showTooltipOnDisabled
      >
        <b-icon icon="home-map-marker" custom-size="x" />
      </Link>
      <Field
        class="ap-gate-code"
        :label="$t('global.gateCode')"
        :maxlength="20"
        v-model="oAddress.gate_code"
      />
      <slot name="field" />
    </div>
  </form>
</template>

<script>
import { Autocomplete, Field, Link, Tooltip } from '@/components';
import AddressLocationModal from './AddressLocationModal';
import { toast } from '@/utils/dialog';
import { addressParser } from '@/config/constants';
import axios from 'axios';
import { Normalize } from '@/utils/Text';
import { equals, clone, pick } from 'ramda';
import { zipcodeRegex } from '@/utils/RegexValidations';

export default {
  components: {
    AddressLocationModal,
    Autocomplete,
    Field,
    Link,
    Tooltip
  },
  created() {
    if (this.pickPosition) {
      this.$store.dispatch('map/handleCurrentPosition');
      this.unsubscribe = this.$store.subscribe(({ type }, { map }) => {
        const mapResponse = map.response || {};
        if (type == 'map/response') {
          if (mapResponse.action == 'position') {
            this.setAddress(mapResponse.data);
            this.status = 'OK';
          }
        }
      });
    }
    if (this.useAddressParser)
      this.addressParserApi = axios.create({
        baseURL: addressParser.api,
        headers: { Authorization: addressParser.key }
      });
    this.addressParserApi.interceptors.request.use((request) => {
      request.data = {
        ...request.data,
        unique_address_service: addressParser.unique_address_service,
        geocode_service: addressParser.geocode_service,
        centers: addressParser.centers,
        typos: addressParser.typos
      };
      return request;
    });
  },
  destroyed() {
    this.unsubscribe && this.unsubscribe();
    if (this.status !== 'OK') {
      this.$store.commit('map/update', { action: 'restore' });
    }
  },
  mounted() {
    this.$emit('input', this.oAddress);
  },
  data() {
    let state = null;
    if (this.state) {
      state = this.state;
    } else if ((this.value?.state && this.value.id) || (this.value?.state && this.value?.city)) {
      state = this.value?.state;
    } else {
      state = this.$store.getters.STATE;
    }
    const oAddress = {
      id: '',
      address: this.address || '',
      address_1: this.address_1 || '',
      city: this.city || '',
      zipcode: this.zipcode || '',
      latitude: 0,
      longitude: 0,
      ...this.value,
      state
    };
    return {
      oLoading: this.loading,
      addressParserApi: null,
      delayer: 0,
      btnActionConfig: {
        label: this.$t('address.parser.parse'),
        icon: 'arrow-split-vertical',
        loading: false,
        disabled: true,
        needsTooltip: false
      },
      lastAddressQuery: null,
      loadingCounter: 0,
      oAddress,
      cloneOf_oAddress: oAddress,
      isFormatAddressRequired: false,
      isAddressMapModalOpen: false,
      status: 'OK',
      unsubscribe: null,
      positionConfirm: false,
      positionRenew: false,
      zipcodeRegex
    };
  },
  computed: {
    positionStatutes() {
      return {
        EMPTY: { id: 1, title: this.$t('address.picker.E'), class: 'is-gray' },
        NEEDS_POSITION_CONFIRM: { id: 2, title: this.$t('address.picker.NPC'), class: 'is-danger' },
        NEEDS_POSITION: { id: 3, title: this.$t('address.picker.NP'), class: 'is-primary' },
        SUCCESSFULL: { id: 4, title: this.$t('address.picker.S'), class: 'is-success' }
      };
    },
    positionStatute() {
      let statute = 'EMPTY';
      if (this.isPickPositionAvailable) {
        if (this.positionRenew) statute = 'NEEDS_POSITION';
        if (!this.positionRenew) statute = 'SUCCESSFULL';
        if (this.positionConfirm) statute = 'NEEDS_POSITION_CONFIRM';
      }
      return this.positionStatutes[statute] || { title: '', class: '' };
    },
    isPickPositionAvailable() {
      const { address, city, zipcode, state } = this.oAddress;
      return !!(address && city && zipcode && state);
    },
    doPickPosition() {
      return this.$listeners.pickPosition || this.pickPosition;
    },
    stateCode() {
      const { state } = this.oAddress;
      return (state && state?.code) || state;
    },
    validatePickPosition() {
      const { address, city, state, zipcode } = this.oAddress;
      return address && city && state && zipcode;
    },
    disabledFields() {
      return this.btnActionConfig.loading;
    }
  },
  methods: {
    NormalizeText(text) {
      return Normalize(text, { lower: true });
    },
    setAddress({ address, position }, update) {
      const {
        street,
        number = '',
        state,
        city,
        zipcode,
        gate_code,
        address_1,
        formatted_address,
        comment
      } = address;
      const { lat: latitude, lng: longitude } = position;
      this.oAddress.comment = null;
      this.$nextTick(() => {
        if (!update && this.pickOnlyPosition) {
          this.oAddress.latitude = latitude;
          this.oAddress.longitude = longitude;
          this.oAddress.formatted_address = formatted_address;
        } else {
          this.oAddress = {
            ...this.oAddress,
            ...(comment && { comment: comment }),
            address: `${number && `${number} `}${street}`,
            address_1,
            gate_code,
            formatted_address,
            state,
            city,
            zipcode,
            latitude,
            longitude
          };
        }
        this.cloneOf_oAddress = clone(this.oAddress);
        this.$emit('input', this.oAddress);
      });
    },
    handleAddressInput(query) {
      this.handleDisableBtnAction(query);
    },
    handleRenewPositionChecker(value) {
      const pickProps = (object) => pick(['address', 'city', 'state', 'zipcode'], object);
      const hasChanges = !equals(pickProps(value), pickProps(this.cloneOf_oAddress));
      if (hasChanges) {
        this.positionRenew = true;
        this.positionConfirm = false;
        this.isFormatAddressRequired = true;
      }
    },
    onPickPosition(oAddress) {
      if (this.validatePickPosition) {
        this.$emit('pickPosition', oAddress);
        if (!this.$listeners.showPosition) {
          this.isAddressMapModalOpen = !this.isAddressMapModalOpen;
          this.status = 'PICKING';
        }
      } else {
        toast('warning', this.$t('errors.fillMandatory'), 10000);
      }
    },
    handleDisableBtnAction(value) {
      const { NormalizeText: NT } = this;
      if (NT(value) === NT(this.lastAddressQuery)) this.btnActionConfig.disabled = true;
      else this.btnActionConfig.disabled = false;
    },
    onMapprPickedPosition(data) {
      this.positionRenew = false;
      const { latitude, longitude, formatted_address } = data;
      this.setAddress({
        address: { formatted_address },
        position: { lat: latitude, lng: longitude }
      });
      this.status = 'OK';
    },
    clear() {
      this.oAddress = {
        address: '',
        state: 'CA',
        address_1: '',
        city: '',
        gate_code: null,
        zipcode: null,
        latitude: null,
        longitude: null
      };
      this.cloneOf_oAddress = clone(this.oAddress);
      this.positionRenew = false;
      this.positionConfirm = false;
    },
    getExtraInfo(query, zipcode) {
      if (!this.useExtraInfoComment) return;
      const zipCodeRx = new RegExp(zipcodeRegex);
      const zipCodeSplit = query.split(zipcode);
      const zipCodeRxSplit = query.split(zipCodeRx);
      const hasZipCodeSplitResult = zipCodeSplit.length > 1;
      const querySplit =
        zipCodeSplit.length > 1 ? zipCodeSplit : zipCodeRxSplit.length > 1 ? zipCodeRxSplit : '';
      let commentExtraInfo = querySplit.length > 1 ? querySplit[querySplit.length - 1] : '';

      if (!/^[a-zA-Z0-9_(]*$/.test(commentExtraInfo.charAt(0)))
        commentExtraInfo = commentExtraInfo.substring(1);
      if (!hasZipCodeSplitResult) {
        toast('warning', this.$t('address.parser.exInfoError'));
      }
      return commentExtraInfo.replace(/^\s+/g, '');
    },
    async processAddress() {
      const query = this.oAddress.address;
      if (!query) return;
      this.btnActionConfig.loading = true;
      try {
        this.handleLoading(true);
        const result = await this.addressParserApi.post('parser/address/parse', { query });
        const statusCode = Number(result.status);
        const errorCases = [206];
        if (errorCases.includes(statusCode)) throw new Error();
        if (!/^20(0|1)$/.test(statusCode)) this.positionConfirm = true;
        const address = result?.data?.data[0]?.address || {};
        const address_1 = result?.data?.data[0]?.extra_info || '';
        const zipcode = String(address?.zip);
        const commentExtraInfo = this.getExtraInfo(query, zipcode);
        const addressUpdate = {
          address: {
            ...(commentExtraInfo && {
              comment: commentExtraInfo
            }),
            city: address?.city,
            address_1,
            formatted_address: address?.formatted_address,
            number: address.number,
            state: address?.state,
            street: address.street,
            zipcode: String(address?.zip)
          },
          position: {
            lat: address?.latitude,
            lng: address?.longitude
          }
        };
        this.lastAddressQuery = query;
        this.positionRenew = false;
        this.btnActionConfig.disabled = true;
        this.setAddress(addressUpdate, true);
      } catch (error) {
        console.log(error);
        this.handleDisableBtnAction(query);
        toast('warning', this.$tc('address.parser.error', 0, [this.oAddress.address]));
      }
      this.btnActionConfig.loading = false;
      setTimeout(() => {
        this.handleLoading(false);
      }, 1000);
    },
    validate() {
      const { positionRenew, $refs, doPickPosition } = this;
      let validation = true;
      validation = $refs.form.checkValidity();
      if (!validation) $refs.form.reportValidity();
      if (this.isPositionRequired && validation && doPickPosition) {
        const { latitude, longitude } = this.oAddress;
        if (positionRenew || !latitude || !longitude) {
          validation = false;
          toast('warning', this.$t('errors.pickPosition'), 10000);
        }
      }
      return validation;
    },
    onChangeState() {
      this.oAddress.city = -1;
      this.oAddress.zipcode = -1;
    },
    handleFormatAddress() {
      const { address, city, zipcode, state } = this.oAddress;
      this.oAddress.formatted_address = `${address}, ${city}, ${state}, ${zipcode}`;
      this.isFormatAddressRequired = false;
    },
    validateSameAs(value, key) {
      const { address, address_1, city, zipcode, latitude, state, longitude } = this.oAddress;
      const extra = value && key ? { [key]: value } : {};
      const temp = { address, address_1, city, zipcode, latitude, state, longitude, ...extra };
      const isSameAsCompared = Object.keys(temp).every(
        (key) => (temp[key] || null) == (this.compareTo[key] || null)
      );
      this.$emit('update:isSameAsCompared', isSameAsCompared);
    },
    setModel(value, key) {
      if (this.compareTo) this.validateSameAs(value, key);
    },
    handleLoading(value) {
      value ? this.loadingCounter++ : this.loadingCounter--;
      this.$emit('update:isLoading', !!this.loadingCounter);
    }
  },
  watch: {
    loading(val) {
      this.oLoading = val;
    },
    value(val) {
      if (equals(clone(val), clone(this.oAddress))) return;
      if (val?.address && !val?.city) this.btnActionConfig.disabled = false;
      if (Object.keys(val).length) {
        this.cloneOf_oAddress = clone(val);
        this.oAddress = val;
      } else this.clear();
    },
    compareTo() {
      this.validateSameAs();
    },
    oAddress: {
      deep: true,
      handler(value) {
        if (this.loading) return;
        this.handleRenewPositionChecker(value);
        if (this.isFormatAddressRequired) {
          this.handleFormatAddress();
        } else {
          if (this.compareTo) this.validateSameAs();
          if (this.isEdit) this.$emit('change', this.oAddress);
        }
        this.cloneOf_oAddress = clone(value);
      }
    }
  },
  props: {
    address: { type: String, default: '' },
    address1: { type: String, default: '' },
    address1MaxLenght: { tpye: String, default: '60' },
    addressMaxLength: { type: String, default: null },
    city: { type: String, default: '' },
    compareTo: { type: Object, default: () => {} },
    dropdownPosition: { type: String, default: 'auto' },
    isEdit: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    isPositionRequired: { type: Boolean, default: true },
    pickOnlyPosition: { type: Boolean, default: true },
    pickPosition: { type: Boolean, default: true },
    state: { type: String, default: null },
    tooltipPosition: { type: String, default: 'top' },
    useAddressParser: { type: Boolean, default: true },
    useExtraInfoComment: { type: Boolean, default: false },
    value: { type: Object, default: () => {} },
    zipcode: { type: String, default: '' }
  }
};
</script>

<style lang="sass" scoped>
.Field
  margin-bottom: 0.75rem!important
  margin-right: 0.75rem
.original-address
  z-index: 4
  position: absolute
  color: $primary
  bottom: 4px
  right: 43px
  background: white
.address-picker
  width: 100%
.ap-address
  width: calc(30% - .75rem)
.ap-address1
  width: calc(20% - .75rem)
.ap-city
  width: calc(25% - .75rem)
.ap-state
  width: calc(10% - .75rem)
.ap-zipcode
  width: calc(15% - .75rem)
.ap-position
  display: flex
  font-size: 32px
  width: 25px
  height: 40px
  margin-left: -5px
  margin-right: auto
  justify-content: center
  align-self: center
  align-items: flex-end
  @each $class,$color in $tooltipColors
    &.#{$class} .icon
      color: $color
</style>

<style lang="sass">
.dark .address-picker .original-address
  background: $dark-900
  color: $blue-300
</style>
