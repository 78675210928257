<template>
  <DeliveryStatusSignUI
    :delivery="deliveryEvent"
    :markerShowNumbers="showNumbers"
    :markerWidth="markerWidth"
    isMapMarker
    :isCollect="deliveryEvent.delivery_event_type_id === 8"
  />
</template>

<script>
import { DeliveryStatusSignUI } from '@/components';
export default {
  components: {
    DeliveryStatusSignUI
  },
  props: {
    showNumbers: { type: Boolean, default: false },
    markerWidth: { type: Number, default: 0 },
    deliveryEvent: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped></style>
