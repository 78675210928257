<template>
  <Field
    class="autocomplete"
    :label="iLabel"
    :required="required"
    :accentColor="accentColor"
    v-on="addEvent"
    :addLabel="addLabel ? addLabel : ''"
    :addPosition="addPosition"
    :permission="permission"
    :isDisabledAddButton="isDisabledAddButton"
    :isDisabledEditButton="isDisabledEditButton"
    :pattern="pattern"
    :simple="false"
    :avoidSpecialChars="false"
  >
    <b-field class="a-field" :class="classes">
      <b-autocomplete
        :clearable="clearable"
        :data="filteredData"
        :keep-first="keepFirst || isOnlyOneResult || isRequieredAndHasText"
        :loading="isLoading"
        :open-on-focus="true"
        :readonly="readonly"
        :disabled="disabled || (!userHasInteracted && (loading || oLoading))"
        :validation-message="validationMessage || $t('validations.checkField')"
        :placeholder="iPlaceholder"
        :value="iValue"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
        @select="onSelect"
        expanded
        ref="autocomplete"
        select-on-click-outside
        v-bind="inputProps"
        v-model="text"
        :icon-right="`${oApi.full || data.length ? 'chevron-down' : 'text-search'}`"
        autocomplete="new-password"
      >
        <template #default="{ option }">
          <slot v-if="$scopedSlots.results" name="results" :data="option" />
          <div v-else v-html="matchedText(option)"></div>
        </template>
        <template v-if="$slots.header" #header> <slot name="header" /> </template>
        <template v-if="$slots.empty" #empty>
          <slot name="empty" />
        </template>
        <template v-if="$slots.footer" #footer>
          <slot name="footer" />
        </template>
      </b-autocomplete>
    </b-field>
  </Field>
</template>

<script>
import { sortBy, concat, prop } from 'ramda';
import Field from './Field.vue';
import { Normalize, Capitalize } from '@/utils/Text';

export default {
  components: {
    Field
  },
  computed: {
    iLabel() {
      const { showResultsCounter, isLoading, oData } = this;
      const total = oData.fields.length;
      let label = this.label;
      if (showResultsCounter && total > 0) {
        label = `${label} ${isLoading ? '-' : `(${total})`}`;
      }
      return label;
    },
    awaitingFirstValue() {
      return this.awaitFirstValue && this.isFirstValue;
    },
    addEvent() {
      const events = {};
      if (this.$listeners.add) {
        events['add'] = this.$listeners.add;
      }
      if (this.$listeners.edit) {
        events['edit'] = this.$listeners.edit;
      }
      return events;
    },
    classes() {
      const classes = [];
      if (this.$listeners.add || this.$listeners.edit) {
        classes.push('hasButtons');
      }
      return classes.join(' ');
    },
    isLoading() {
      return this.loading || this.oLoading || this.awaitingFirstValue;
    },
    sortedDataByQuery() {
      const { oData, normalizeText, field } = this;
      if (!field || !this.sortData) return oData.fields;
      const sortByField = sortBy(prop(field));
      const queryAtStart = [];
      const queryOtherPosition = [];
      const query = oData.query;
      oData.fields.map((oField) => {
        const oFieldQuery = oField?.queryKey || normalizeText(oField[field]);
        if (oFieldQuery.indexOf(query) === 0) queryAtStart.push(oField);
        else queryOtherPosition.push(oField);
      });
      return concat(sortByField(queryAtStart), sortByField(queryOtherPosition));
    },

    filteredDataByModel() {
      const { sortedDataByQuery, filteredByModel, model } = this;
      const fields = sortedDataByQuery;
      if (model && filteredByModel.length)
        return fields.filter((field) => filteredByModel.indexOf(field[model]) < 0);
      else return fields;
    },
    filteredData() {
      const { field, normalizeText, filteredDataByModel, selected, model } = this;
      let fields = filteredDataByModel;
      if (!fields.length) return [];
      const query = normalizeText(this.text) || '';
      if (this.isFilterless) {
        let data = fields;
        if (selected) {
          const compareObjects = !!(typeof selected === 'object' && model);
          data = [selected];
          const query = normalizeText(selected[field] || selected);
          const tempData = fields.filter((opt) => {
            const isSameObject = compareObjects && selected[model] === opt[model];
            const queryKey = opt?.queryKey || normalizeText(opt[field] || opt);
            return compareObjects ? !isSameObject : true && queryKey != query;
          });
          data = data.concat(tempData);
        }
        return data;
      } else {
        const useExactMatch = query && !this.userHasInteracted;
        if (!this.localFiltering) return fields;
        return fields.filter((opt) => {
          const normalizedOption = model && normalizeText(opt[model]);
          if (normalizedOption === query) return true;
          const queryKey = opt?.queryKey || normalizeText(opt[field] || opt);
          if (typeof queryKey === 'object') return true;
          if (useExactMatch) return queryKey === query;
          else return queryKey.indexOf(query) >= 0;
        });
      }
    },
    isRequieredAndHasText() {
      return this.required && this.text?.length > 0;
    },
    isOnlyOneResult() {
      return this.filteredData.length === 1;
    },
    iValue() {
      const { field, value, model, oData } = this;
      let result = null;
      if (model && value) {
        const rDat = oData.fields.filter((d) => d[model] == value)[0];
        result = (rDat && rDat[field]) || '';
      }
      if (result) return result;
      else return value;
    },
    iPlaceholder() {
      let placeholder = this.placeholder;
      if (!placeholder && this.label) {
        if (this.filteredData.length)
          placeholder = `${this.$tc('global.placeholder.select', 1, [this.label])}`;
        else placeholder = `${this.$tc('global.placeholder.search', 1, [this.label])}`;
      }
      return Capitalize(placeholder);
    },
    params() {
      const { oApi, text } = this;
      const inputText = Normalize(text, {
        encodeUri: true,
        replace: { find: ['\n'], value: ['\\n'] }
      });
      let paramsStr = '';
      const params = [];
      if (oApi.query && inputText) params.push(`${oApi.query}=${inputText}`);
      if (oApi.params && oApi.params.length)
        oApi.params.map((p) => (p.id && p.value ? params.push(`${p.id}=${p.value}`) : null));
      if (oApi.full) params.push(`per_page=99999`);
      paramsStr = params.join('&');
      return paramsStr ? `?${paramsStr}` : '';
    },
    allowOnInputRequest() {
      const { api, text, selected, field, minToSearch, oData } = this;
      const selectedStr = selected && selected?.[field];
      const hasLocalResults =
        this.localFiltering && !!this.filteredData.length && text?.indexOf(oData.query) >= 0;
      const hasApi = !!api?.url;
      const isCurrent = text == selectedStr;
      const isMinToSeach = text?.length >= minToSearch;
      const isPartial = !api.full;

      return hasApi && !hasLocalResults && !isCurrent && isMinToSeach && isPartial;
    },
    inputProps() {
      const inputProps = { ...this.$props, ...this.$attrs };
      delete inputProps.api;
      delete inputProps.minToSearch;
      delete inputProps.model;
      delete inputProps.accentColor;
      delete inputProps.model;
      delete inputProps.keepFirst;
      return inputProps;
    }
  },
  data() {
    const fields = this.data;
    this.generateQueryKey(fields);
    return {
      oApi: this.api,
      userHasInteracted: false,
      isDropDownHover: false,
      isFilterless: false,
      isFirstValue: true,
      fetchingData: false,
      hasBlurPromise: false,
      cancelToken: null,
      timeout: { getData: 0, selector: 0 },
      oLoading: false,
      oData: { fields, query: '' },
      selected: null,
      text: ''
    };
  },
  beforeMount() {
    if (this.iValue) this.isFirstValue = false;
  },
  async mounted() {
    await this.getFullData();
    if ((this.awaitFirstValue && !this.isFirstValue) || !this.awaitFirstValue)
      this.setInitialText(true);
    if (this.$refs.autocomplete) {
      this.$refs.autocomplete.$refs.dropdown.onmouseenter = () => {
        this.isDropDownHover = true;
      };
      this.$refs.autocomplete.$refs.dropdown.onmouseleave = () => {
        this.isDropDownHover = false;
      };
    }
  },
  destroyed() {
    clearTimeout(this.timeout.getData);
  },
  methods: {
    cancelRequest() {
      clearTimeout(this.timeout.getData);
      if (this.cancelToken) this.cancelToken.cancel('Avoid multiple');
      this.oLoading = false;
      this.fetchingData = false;
    },
    async makeRequest() {
      try {
        const { api, params } = this;
        this.oLoading = true;
        this.cancelToken = this.Api.cancelToken;
        const { data } = await this.Api.get(`${api.url}${params}`, {
          cancelToken: this.cancelToken.token
        });
        this.oLoading = false;
        this.fetchingData = false;
        return data;
      } catch (error) {
        this.oLoading = false;
        this.fetchingData = false;
        console.error(error);
        return { data: [] };
      }
    },
    async getFullData() {
      if (this.api.full) {
        const { storeResults } = this;
        let data = storeResults ? JSON.parse(sessionStorage.getItem(this.api.url)) : null;
        if (!data) {
          const result = await this.makeRequest();
          data = result;
        }
        if (storeResults) sessionStorage.setItem(this.api.url, JSON.stringify(data));
        this.setData(data);
      }
    },
    async getData() {
      if (this.allowOnInputRequest) {
        this.cancelRequest();
        this.fetchingData = true;
        this.timeout.getData = setTimeout(async () => {
          const data = await this.makeRequest();
          this.setDefaultData(data);
          this.setData(data);
          if (this.hasBlurPromise) {
            if (data.length) {
              this.setFirstItem();
              this.hasBlurPromise = false;
            } else {
              this.reset();
            }
          }
        }, 500);
      }
    },
    setDefaultData(data) {
      this.$emit('defaultData', data[0]);
    },
    onBlur() {
      if (this.selectOnBlur) {
        clearTimeout(this.timeout.selector);
        setTimeout(() => {
          if (this.fetchingData) {
            this.hasBlurPromise = true;
          } else if (!this.isDropDownHover && !this.selected) {
            // this.text = '';
            this.reset();
            this.isDropDownHover = false;
          }
        }, 100);
      }
    },
    onFocus() {
      this.userHasInteracted = true;
      this.timeout.selector = setTimeout(() => {
        this.isFilterless = true;
        this.$refs.autocomplete?.$refs?.input?.$refs?.input?.select();
      }, 50);
    },
    reset() {
      this.text = '';
      this.selected = null;
    },
    onInput(text) {
      text = Normalize(text);
      this.text = text;
      this.oData.query = text;
      this.isFilterless = false;
      this.getData();
      this.$emit('primitiveInput', text);
    },

    onSelect(selected) {
      const { clearOnSelect, clearable, model, text } = this;
      this.isFirstValue = false;
      if (this.selected == selected) return;
      this.selected = selected;
      const item = model && selected ? selected[model] : selected;
      this.emit(item);
      this.isDropDownHover = false;
      const tempText = text;
      if (clearOnSelect) this.reset();
      else if (tempText && !item && !clearable)
        setTimeout(() => {
          this.text = tempText;
        }, 10);
    },
    emit(data) {
      this.$emit('select', data);
      this.$emit('input', data);
      this.$emit('selectRaw', this.selected);
    },
    matchedText(option) {
      const { field, normalizeText, oData } = this;
      if (!field) return option;
      const text = field ? option[field] : option;
      const query = oData.query;
      const queryIndex = normalizeText(text).indexOf(query);
      const queryLength = query.length;
      const textLength = text.length;
      const matchedSize = queryIndex + queryLength - 1;
      let hasEndedTag = false;
      if (!text || queryIndex === -1 || !queryLength) return text;
      else {
        let matchedText = '';
        [...text].map((letter, i) => {
          const isFirstMatch = i === queryIndex;
          const isLastMatch = i === textLength;
          const hasMatchedFullText = i === matchedSize;

          if (isFirstMatch) matchedText += '<b>';
          matchedText += letter;
          if ((isLastMatch && !hasEndedTag) || hasMatchedFullText) {
            matchedText += '</b>';
            hasEndedTag = true;
          }
        });
        return matchedText;
      }
    },
    normalizeText(text = '') {
      return Normalize(text, { lower: true, replace: { find: [','], value: [''] } });
    },
    generateQueryKey(fields = []) {
      return fields.map((field) => {
        if (typeof field === 'object')
          field.queryKey = this.normalizeText(field[this.field || 'text']);
      });
    },
    setData(fields) {
      if (this.dataPreProcessor) fields = this.dataPreProcessor(fields);
      fields = [...this.append, ...fields, ...this.prepend];
      if (this.ignoreElement && this.model) {
        fields = fields.filter((field) => field[this.model] !== this.ignoreElement);
      }
      this.generateQueryKey(fields);
      this.oData = { fields, query: this.normalizeText(this.text) };
      this.$emit('allData', fields);
    },
    setSelected() {
      if (this.filteredData.length == 1) {
        this.selected = this.filteredData[0];
        this.$refs?.autocomplete?.setSelected(this.filteredData[0] || '');
      }
    },
    setInitialText() {
      const { iValue, setFirst, isOnlyOneResult } = this;
      if (iValue) {
        this.hasBlurPromise = true;
        this.text = String(iValue);
        this.setSelected();
      } else if (setFirst || isOnlyOneResult) {
        this.setFirstItem();
        this.setSelected();
      }
      /*
      if (setFirst || isOnlyOneResult) {
        this.setFirstItem();
        this.setSelected();
      } else if (model && value && typeof value == 'string') {
        this.text = String(iValue);
      } else if (isAtMount && !this.selected) {
        let item = this.selected;
        if (field && item) item = item[field];
        else if (model && item) item = item[model];
        this.emit(item ? item : '');
      }
      */
    },
    setFirstItem() {
      const { field, filteredData } = this;
      const item = filteredData[0];
      if (item) {
        this.text = item[field];
        this.onSelect(item);
      } else {
        this.reset();
      }
    },
    updateValue(value) {
      if (typeof value == 'object' && value) value = value[this.field];
      else if (typeof value == 'string') this.text = value;
      else if (typeof value == 'number') this.text = this.iValue;
      else this.text = '';
      this.setSelected();
      if (this.isFirstValue) this.isFirstValue = false;
    }
  },
  watch: {
    data(data) {
      this.setData(data);
      this.setInitialText();
      this.setFirstItem();
    },
    async 'api.params'(data) {
      if (JSON.stringify(data[0]) == JSON.stringify(this.oApi.params[0])) return;
      this.oApi.params = data;
      if (this.api.full) {
        this.reset();
        await this.getFullData();
        this.setInitialText();
      }
    },
    value(value) {
      const { selected, model } = this;
      if (selected) {
        const selectedValue = model ? selected[model] : selected;
        if (value == selectedValue) return;
      }
      if (this.text === value) return;
      // if -1 clear local data
      if (value === -1) {
        this.setData([]);
        value = '';
      }
      this.updateValue(value);
    },
    oLoading(value) {
      this.$emit('update:loading', value);
      this.$emit('isLoading', value);
    }
  },
  props: {
    // add: { type: Boolean, default: false },
    accentColor: { type: String },
    addLabel: { type: String },
    addPosition: { type: String, default: 'top' },
    api: { type: Object, default: () => ({ full: false, query: null, params: [] }) },
    append: { type: Array, default: () => [] },
    awaitFirstValue: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    clearOnSelect: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
    dataPreProcessor: { type: Function, default: null },
    disabled: { type: Boolean, default: false },
    field: { type: String },
    filteredByModel: { type: Array, default: () => [] },
    ignoreElement: { default: null },
    keepFirst: { type: Boolean, default: false },
    label: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    localFiltering: { type: Boolean, default: true },
    minToSearch: { type: Number, default: 3 },
    model: { type: String },
    pattern: { type: [String, RegExp], default: null },
    permission: { type: Object, default: () => {} },
    placeholder: { type: String, default: null },
    prepend: { type: Array, default: () => [] },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    showResultsCounter: { type: Boolean, default: false },
    setFirst: { type: Boolean, default: false },
    selectOnBlur: { type: Boolean, default: true },
    storeResults: { type: Boolean, default: false },
    sortData: { type: Boolean, default: true },
    validationMessage: { type: String, default: null },
    value: { default: null },
    isDisabledAddButton: { type: Boolean, default: false },
    isDisabledEditButton: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.autocomplete
  &.hasButtons
    ::v-deep
      &>.field-body
        .a-field
          flex-shrink: 1
          margin: 0
          input
            border-top-right-radius: 0!important
            border-bottom-right-radius: 0!important
  .a-field
    width: 100%
    position: relative
  ::v-deep
    .dropdown-menu
      min-width: 100%
      width: 100%
      max-width: calc( 100% + 100px )
      text-transform: uppercase
      p
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
      a div
        white-space: normal
    .dropdown-item
      padding-right: 5px
</style>
