<template>
  <Dropdown
    v-if="hasPermission"
    class="CDPSelector-dropdown"
    :icon="icon"
    :showLabel="false"
    :items="filteredOptions"
    :disabled="!filteredOptions.length"
    position="is-bottom-left"
    useButton
    :skeletonLoading="skeletonLoading || loading"
    useCustomItem
    :label="$t('cdp.filterBy')"
    :close-on-click="false"
    :hideIfNotAllowedItems="false"
  >
    <template v-slot:default="{ items }">
      <b-dropdown-item v-for="item in items" :key="item.id">
        <b-checkbox v-model="checkedOptions" :native-value="item.id">
          <div class="flex f-ai">
            <div class="color-identifier" :style="`background-color:${item.color}`"></div>
            {{ item.name }}
          </div>
        </b-checkbox>
      </b-dropdown-item>
    </template>
  </Dropdown>
</template>

<script>
import { Dropdown } from '../';
import { getCDPs_active } from '../../Services/CPDs';
import { Permissions } from '../../utils/Secure';

export default {
  components: {
    Dropdown
  },
  async mounted() {
    this.loading = true;
    await this.getCDPs();
    this.loading = false;
  },
  data() {
    return {
      options: [],
      checkedOptions: this.value,
      loading: false
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((option) => this.filterIds.find((F) => F === option.id));
    },
    hasPermission() {
      return this.Secure.permissionValidator(Permissions.CDPs.read);
    },
    icon() {
      return `filter-menu${this.checkedOptions.length ? '' : '-outline'}`;
    }
  },
  methods: {
    async getCDPs() {
      try {
        const cancelToken = this.Api.cancelToken;
        this.cancelToken = cancelToken;
        const { data } = await getCDPs_active({ cancelToken });
        this.options = data;
        this.$emit('loaded', data);
      } catch (error) {
        console.log('::::Err', error);
      }
    }
  },
  watch: {
    checkedOptions(value) {
      this.$emit('input', value);
    }
  },
  props: {
    value: { type: Array, default: () => [] },
    filterIds: { type: Array, default: () => [] },
    skeletonLoading: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.CDPSelector-dropdown
  max-width: 40px
  margin-left: 1rem
.dropdown-item
  padding: 0 1rem
.checkbox
  width: 100%
  padding: 0.375rem 0
.color-identifier
  margin: 0 5px
  width: 5px
  height: 15px
</style>
