<template>
  <div v-if="isMapMarker" :class="statusClass">
    <CustomIcon v-if="markerShowNumbers" :number="markerSecuence" :width="markerWidth">
      <template #svg>
        <MapMarker />
      </template>
    </CustomIcon>
    <CustomIcon v-else :mdiIcon="status.icon" :width="markerWidth">
      <template #svg>
        <MapMarker />
      </template>
    </CustomIcon>
  </div>
  <b-tag
    v-else
    class="deliveryStatusUI"
    :class="classes"
    rounded
    @click.native.stop="statusTagClick"
  >
    <span v-if="totalDeliveryMessage && delivery.delivery_event_type_id == 2">{{
      totalDeliveryMessage
    }}</span>

    {{ status.name }}
  </b-tag>
</template>

<script>
import { CustomIcon, MapMarker } from '@/components';
import { getTypeData, eventStatusAsEventType } from './DeliveryTypes';

export default {
  components: {
    MapMarker,
    CustomIcon
  },
  computed: {
    isCollected() {
      let { strId } = this.status;

      return strId === 'successful' && this.isCollect;
    },
    isReturned() {
      let { strId } = this.status;
      let { delivery_event_status_id } = this.delivery;

      return this.isCollect && strId === 'failed' && delivery_event_status_id === 19;
    },
    classes() {
      let { strId } = this.status;

      /* En el caso de que sea un strId de tipo "Pick-up" se sustituyó la clase
      de la tag para qur tomara un color gris, debido a que se solicitó
      que la tag tuviera un color diferente a los iconos. */
      if (strId === 'pick-up') strId = 'scheduled';
      else if (this.isReturned) strId = 'successful';
      else if (this.isCollected) strId = 'working';

      const classes = [strId];
      if (this.$listeners.statusTagClick) classes.push('clickable');
      return classes.join(' ');
    },
    markerSecuence() {
      return this.delivery.secuence - this.$store.getters['deliveries/OFFSET'];
    },
    useEventTypes() {
      const { event_type_is_mandatory, delivery_event_type_id } = this.delivery;
      return (
        event_type_is_mandatory || !!(eventStatusAsEventType.indexOf(delivery_event_type_id) + 1)
      );
    },
    status() {
      const { useEventTypes, tryies } = this;
      const {
        delivery_event_status_id,
        delivery_event_type_id,
        participant_full_name,
        participant_name
      } = this.delivery;
      const deff = {
        participant_name: participant_name || participant_full_name
      };

      let id = useEventTypes ? delivery_event_type_id : delivery_event_status_id;
      const status = getTypeData({ id, useEventTypes: useEventTypes, tryies });

      return { ...deff, ...status };
    },
    statusClass() {
      if (this.isReturned) return 'map-successful';
      else if (this.isCollected) return 'map-working';

      return `map-${this.status.strId}`;
    }
  },
  methods: {
    statusTagClick() {
      this.$emit('statusTagClick', this.status);
    }
  },
  watch: {
    status: {
      immediate: true,
      deep: true,
      handler(value, oldVal) {
        if (value?.strId && value?.strId !== oldVal?.strId) {
          this.$emit('status', value);
        }
      }
    }
  },

  props: {
    delivery: { type: Object, default: () => {} },
    isMapMarker: { type: Boolean, default: false },
    markerShowNumbers: { type: Boolean, default: false },
    markerWidth: { type: Number, default: 0 },
    tryies: { type: Number, default: 0 },
    totalDeliveryMessage: { type: String, default: null },
    categoryType: { type: String, default: null },
    isCollect: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.tag
  font-size: 14px
  color: $dark-600
  border: 1px solid $gray-100
  background: $gray-100
  margin-bottom: 0.2rem
  &.clickable
    cursor: pointer
  ::v-deep
    & + *
      margin-left: 3px

@each $i,$color in $deliveryStatusColors
  .#{$i}
    border-color: $color
    background: $color
@each $i,$color in $deliveryStatusColorsDark
  .map-#{$i}
    .MapMarker
      ::v-deep
        .mm-border
          fill: darken($color,15)
        .mm-regular
          fill: $color
        .mm-darken
          fill: darken($color,7)
</style>
<style lang="sass">
.dark
  .deliveryStatusUI
    color: $main-background!important
    &.scheduled, &.picked-up,&.pick-up, &.return, &.start-lunch-break,&.end-lunch-break,&.scheduling
      color: $dark!important
    @each $i,$color in $deliveryStatusColorsDark
      &.#{$i}
        border-color: $color!important
        background: $color!important
</style>
