<template>
  <component :is="polylineType" v-bind="$props" />
</template>

<script>
import { isGoogleMap } from './helper';

export default {
  components: {
    gMapPolyline: () => import('./gMaps/gMapsPolyline'),
    osPolyline: () => import('./OSM/osPolyline')
  },
  computed: {
    isGmaps() {
      return isGoogleMap();
    },
    polylineType() {
      return this.isGmaps ? 'gMapPolyline' : 'osPolyline';
    },
    gOptions() {
      return { strokeColor: this.color, strokeWeight: this.weight };
    }
  },
  props: {
    color: { type: String, default: '#026C75' },
    encodedPath: { type: String, default: '' },
    path: { type: Array, default: () => [] },
    weight: { type: Number, default: 3 }
  }
};
</script>
