<template>
  <div
    class="delivery-tag"
    :class="`delivery-tag__${deliveryUI.class}`"
    rounded
    v-if="component === 'tag'"
  >
    <b-icon :icon="deliveryUI.icon" size="is-small" />
    <label>{{ text || deliveryUI.text }}</label>
  </div>
  <div
    v-else-if="component === 'simple-icon' || component === 'simple-text'"
    class="simple-delivery"
    :class="simpleClass[deliveryUI.class]"
  >
    <b-icon
      custom-class="rush-icon"
      :icon="deliveryUI.icon"
      :class="`${component !== 'simple-icon' ? 'mx-1' : ''}`"
    />
    <span v-if="component !== 'simple-icon'"> {{ text || deliveryUI.text }}</span>
  </div>
</template>

<script>
export default {
  computed: {
    simpleClass() {
      return {
        priority: 'simple-delivery__priority',
        rush: 'simple-delivery__rush',
        door: 'simple-delivery__la-door',
        collect: 'simple-delivery__collect',
        refrigerated: 'simple-delivery__refrigerated',
        copayment: 'simple-delivery__copayment'
      };
    },
    deliveryUI() {
      switch (this.type) {
        case 'rush':
          return {
            icon: 'alpha-r-circle',
            text: this.$t('global.rush'),
            class: 'rush'
          };
        case 'priority':
          return {
            icon: 'alpha-p-circle',
            text: this.$t('global.priority'),
            class: 'priority'
          };
        case 'leave-at-door':
          return {
            icon: 'door',
            text: this.$t('global.leave-at-door'),
            class: 'door'
          };
        case 'refrigerated':
          return {
            icon: 'snowflake',
            text: this.$t('global.refrigerated'),
            class: 'refrigerated'
          };
        case 'collect':
          return {
            icon: 'package-variant',
            text: this.$t('global.collect'),
            class: 'collect'
          };
        case 'copayment':
          return {
            icon: 'currency-usd ',
            text: this.$t('global.copayment'),
            class: 'copayment'
          };
        default:
          return {};
      }
    },
    componentConfiguration() {
      switch (this.component) {
        case 'simple-text':
          return {
            icon: 'alpha-r-circle',
            text: this.$t('global.rush')
          };
        case 'simple-icon':
          return {
            icon: 'alpha-p-circle',
            text: false
          };
        default:
          return {};
      }
    }
  },
  props: {
    icon: { type: String, default: 'radiobox-blank' },
    text: { type: String, default: null },
    type: { type: String, default: null },
    component: { type: String, default: null }
  }
};
</script>

<style lang="sass" scoped>
.simple-delivery
  color: $blue-700
  display: flex
  align-items: center
  font-size: 14px
  span:nth-child(2)
    margin-right: 0.2rem
    font-weight: 500
  .icon ::v-deep
    width: 22px
    i
      position: relative
    i:before
      font-size: 1.4rem
      position: absolute
      white-space: pre
      display: inline
      top: 0
      transform: translate(-85%, -17px)
  &__priority .icon ::v-deep > i
    color: $green-700
  &__rush .icon ::v-deep > i
    color: #C4B541
  &__la-door .icon ::v-deep > i
    color: $pink-700
  &__refrigerated .icon ::v-deep > i
    color: $blue-700
  &__collect .icon ::v-deep > i
    color: $orange-650
  &__collect .icon ::v-deep > i, &__refrigerated .icon ::v-deep > i
    &:before
      font-size: 1rem
      transform: translate(-85%, -12px)
.delivery-tag
  align-items: center
  border-radius: 1rem
  display: flex
  font-size: 14px
  font-weight: 600
  margin-bottom: 0.75rem
  margin-right: 0.2rem
  padding: 0.2rem 0.6rem
  & > span
    display: flex
  label
    margin-left: 0.4rem
  .icon ::v-deep
    i:before
      font-size: 1.2rem
  &__rush
    background-color: $yellow-625
    color: $yellow-100
    .icon ::v-deep > i
      color: $yellow-100
  &__priority
    background-color: $green-200
    color: $green-700
    .icon ::v-deep > i
      color: $green-700
  &__door
    background-color: #FDD6EE
    color: $pink-900
    .icon ::v-deep > i
      color: $pink-900
  &__collect
    background-color: #FDEDE4
    color: $orange-800
    .icon ::v-deep > i
      color: $orange-800
  &__copayment
    background-color: $light-blue-100
    color: $light-blue-800
    .icon ::v-deep > i
      color: $light-blue-800
  &__refrigerated
    background-color: $blue-100
    color: $blue-700
    .icon ::v-deep > i
      color: $blue-700
</style>

<style lang="sass" scoped>
.dark
  .simple-delivery
    &__priority .icon ::v-deep > i
      color: $green-400
    &__rush .icon ::v-deep > i
      color: $yellow-400
    &__la-door .icon ::v-deep > i
      color: $pink-400
    &__refrigerated .icon ::v-deep > i
      color: $blue-400
    &__collect .icon ::v-deep > i
      color: $orange-400
  .delivery-tag
    &__rush, &__priority, &__door, &__collect, &__refrigerated, &__copayment
      color: #FFFFFF
      .icon ::v-deep > i
        color: #FFFFFF
    &__rush
      background-color: $yellow-900
    &__priority
      background-color: $green-700
    &__door
      background-color: $pink-800
    &__collect
      background-color: $orange-650
    &__copayment
      background-color: $light-blue-600
    &__refrigerated
      background-color: $blue-600
</style>
