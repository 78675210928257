<template>
  <svg
    class="MapMarker"
    :width="width"
    :height="height"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="mm-border"
      d="M16.8562 33.5397L15.5155 32.1999C14.3533 31.0376 4.16406 20.6246 4.16406 12.8655C4.16782 9.50049 5.50623 6.27437 7.88566 3.89494C10.2651 1.51551 13.4912 0.1771 16.8562 0.17334C20.221 0.1771 23.4469 1.51542 25.8262 3.89468C28.2054 6.27395 29.5437 9.49984 29.5475 12.8646C29.5475 20.6238 19.3583 31.0368 18.196 32.199L16.8562 33.5397ZM16.8562 3.96545C14.4965 3.96804 12.2341 4.90652 10.5655 6.57502C8.89681 8.24352 7.9581 10.5058 7.95528 12.8655C7.95528 17.4221 13.5223 24.4638 16.8562 28.1103C20.1893 24.4638 25.7563 17.4221 25.7563 12.8655C25.7537 10.5058 24.8152 8.24343 23.1467 6.57476C21.4782 4.90609 19.216 3.96738 16.8562 3.96456V3.96545Z"
      :fill="lightenDarkenColor(-0.5, hashedColor)"
    />
    <path
      class="mm-regular"
      d="M16.8571 2.06886C15.4388 2.06699 14.034 2.34497 12.7232 2.88689C11.4125 3.4288 10.2215 4.224 9.21862 5.22692C8.2157 6.22985 7.4205 7.42079 6.87858 8.73154C6.33667 10.0423 6.05869 11.4471 6.06056 12.8654C6.06056 20.0625 16.8571 30.8582 16.8571 30.8582C16.8571 30.8582 27.6528 20.0625 27.6528 12.8654C27.6548 11.4471 27.3769 10.0423 26.8351 8.73161C26.2933 7.42087 25.4981 6.22992 24.4953 5.22698C23.4924 4.22404 22.3016 3.42883 20.9909 2.8869C19.6802 2.34498 18.2754 2.06699 16.8571 2.06886Z"
      :fill="hashedColor"
    />
    <path
      class="mm-darken"
      d="M27.6517 12.9037C27.6534 11.4855 27.3754 10.0809 26.8334 8.77041C26.2915 7.45988 25.4964 6.26914 24.4935 5.26639C23.4907 4.26364 22.2999 3.46859 20.9893 2.92677C19.6788 2.38496 18.2742 2.10703 16.856 2.1089H16.8418V30.8867L16.8524 30.8973C16.8524 30.8973 27.6517 20.1008 27.6517 12.9037Z"
      :fill="lightenDarkenColor(-0.25, hashedColor)"
    />
  </svg>
</template>

<script>
import { pSBC } from '@/utils/Colors.js';
export default {
  updated() {
    console.log('::📍 ⏱️');
  },
  computed: {
    hashedColor() {
      return this.color.includes('#') ? this.color : `#${this.color}`;
    }
  },
  methods: {
    lightenDarkenColor(p, c0, c1, l) {
      return pSBC(p, c0, c1, l);
    }
  },
  props: {
    color: { type: String, default: '#7685F9' },
    height: { type: Number, default: 34 },
    width: { type: Number, default: 34 }
  }
};
</script>

<style lang="sass" scoped></style>
