export default {
  namespaced: false,
  state: {
    AppConf: JSON.parse(localStorage.getItem('AppConf')) || { IsMenuOpen: true }
  },
  getters: {
    AppConf: (state) => state.AppConf || {}
  },
  mutations: {
    setAppConf(state, conf) {
      state.AppConf[conf.name] = conf.value;
      localStorage.setItem('AppConf', JSON.stringify(state.AppConf));
    }
  },
  actions: {
    AppConf: (context, config) => {
      const keys = Object.keys(config);
      keys.map((key) => {
        context.commit('setAppConf', { name: key, value: config[key] });
      });
    }
  }
};
