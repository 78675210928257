<template>
  <span
    class="CustomIcon"
    :class="(iconFile || $slots.svg) && (mdiIcon || number != null) ? 'icon-mixed' : ''"
    :style="`width:${width}px;height:${height}px;`"
  >
    <slot v-if="$slots.svg" name="svg" />
    <b-image v-if="iconFile" :src="require(`@/assets/icons/${iconFile}`)" />
    <b-icon ref="icon" v-if="mdiIcon" :icon="mdiIcon" custom-class="ci-icon" />
    <span v-else-if="number != null" class="ci-number">{{ number }}</span>
  </span>
</template>

<script>
const fontSizeMultiplier = 0.45;
const topMultiplier = 0.15;
export default {
  mounted() {
    this.updateIconSize();
  },
  data() {
    return {
      cssConfig: {
        fontSize: this.width * fontSizeMultiplier,
        top: this.height * topMultiplier
      }
    };
  },

  methods: {
    updateIconSize() {
      const { fontSize, top } = this.cssConfig;
      if (this.autoSize)
        this.$refs.icon.$el.style.cssText = `font-size:${fontSize}px;top:${top}px;align-items: baseline;`;
    }
  },
  watch: {
    height(height) {
      this.cssConfig.top = height * topMultiplier;
      this.updateIconSize();
    },
    width(width) {
      this.cssConfig.fontSize = width * fontSizeMultiplier;
      this.updateIconSize();
    }
  },
  props: {
    autoSize: { type: Boolean, default: false },
    height: { type: Number, default: 35 },
    iconFile: { type: String, default: '' },
    mdiIcon: { type: String, default: '' },
    number: { type: Number, default: null },
    width: { type: Number, default: 50 }
  }
};
</script>

<style lang="sass" scoped>
.CustomIcon
  margin-right: 5px
  &.icon-mixed
    position: relative
    display: flex
    justify-content: center
    .ci-number,
    .icon
      position: absolute
      top: 2px
      color: white
      z-index: 201
    .ci-number
      font-weight: bold
      top: 4px
      font-size: 13px
  ::v-deep
    .ci-icon:before
      font-size: inherit
</style>
